import { graphql } from 'gatsby'
import { Header, Footer } from '@/components'
import { Box } from '@mui/material'
import React from 'react'
import { PrivacyNoticeDetailDrawer } from '@/components/PrivacyNoticeDetailDrawer'

const PrivacyPolicyBrazil = (props) => {
  const footerLocale = JSON.parse(props.data.footerLocale.data)

  const { detailDrawerOpen, showDetailDrawer, detailDrawerContent } = PrivacyNoticeDetailDrawer({
    pageLocale: footerLocale,
  })
  const content = (
    <Box
      sx={{
        margin: '0px 24px 24px',
        paddingTop: '120px',
        'h2,h3,h4': {
          fontWeight: '700',
        },
      }}
    >
      <h2 style={{ textAlign: 'center' }}>Cookie Policy</h2>
      <p>Updated: 08/16/2024</p>
      <p>
        iMile Logistics Co., Ltd. (refer to as "iMile UK") and its affiliates (collectively,
        "iMile", "we", "us", and "our") respect your privacy. We hereby develop this Cookie Policy
        (refer to as “this Policy”) to help you understand how we use cookies on this website (refer
        to as "our Website"), and to demonstrate our firm commitment to the privacy of your personal
        information. Please take a moment to read this Policy carefully and contact us if you have
        any questions.
      </p>
      <p>
        More information on data protection you can find in our Privacy Policy under{' '}
        <a href='https://www.imile.com/privacy-policy-uk' target='_blank'>
          https://www.imile.com/privacy-policy-uk
        </a>{' '}
        .
      </p>
      <h3>What are cookies?</h3>
      <p>
        Cookies and similar technologies are very small text documents or pieces of code that often
        contain a unique identification code. When you visit a website or use a mobile application,
        a computer asks your computer or mobile device for permission to save this file on your
        computer or mobile device and gain access to information. Information collected through
        cookies and similar technologies may include the date and time of the visit and how you use
        a particular website or mobile application.
      </p>
      <h3>Why do we use cookies</h3>
      <p>
        Cookies can remember your sign-in credentials, so you don’t have to enter those credentials
        each time you log on to a service. The cookies ensure that we can see how our website is
        used and how we can improve it. Furthermore, depending on your preferences our own cookies
        may be used to present you with targeted advertisements that match your personal interests.
      </p>
      <h3>What type of cookies do we use?</h3>
      <p>iMile UK uses the following types of cookies on this website:</p>
      <h3>Cookies by Party</h3>
      <h4>First party cookies</h4>
      <p>
        First-party cookies are stored on a website (domain) a user has visited directly. Publishers
        use these cookies to collect analytical data and optimize website functionality, like
        remembering the language preferences. First-party cookies are active by default and will not
        go away any time soon. This is because they are essential to perform key website features.
      </p>
      <h4>Third party cookies</h4>
      <p>
        Third party cookies are set by an organization other than the owner of the website you are
        visiting. For example, the website may use a third-party analytics provider that sets its
        own cookie to perform this service. The website you are visiting may also contain content
        embedded from, for example, YouTube or Facebook, and these websites may set their own
        cookies.
      </p>
      <h3>Cookies by Duration</h3>
      <h4>Session cookies</h4>
      <p>
        Session cookies are stored for the duration of a website visit and then automatically
        deleted when the browser is closed. They ensure, for example, that video and audio files can
        be played, your user input is temporarily stored during the input time and thus the user
        experience is improved.
      </p>
      <h4>Persistent cookies</h4>
      <p>
        Persistent cookies remain on your terminal device even after you close the browser. These
        cookies can, for example, store your user preferences, such as language settings, and
        analyze user behavior on our website. The storage period of persistent cookies is determined
        individually per cookie. After expiry of the period, they are automatically deleted.{' '}
      </p>
      <h3>Cookies by Category</h3>
      <h4>Essential cookies</h4>
      <p>
        These cookies are essential for the website to function properly and cannot be switched off
        without affecting the use of the site. They are usually only set in response to actions you
        take that amount to a request for services, such as setting your privacy preferences,
        logging in or filling in forms. These cookies do not store any personal data.
      </p>
      <p>Some of the following actions can be performed by using these cookies:</p>
      <ul>
        <li>Store articles in a shopping cart for online purchases</li>
        <li>Save your cookie preferences for this website</li>
        <li>Saving language preferences</li>
        <li>Log in to our portal. We need to check whether you are logged in.</li>
      </ul>
      <h4>Performance cookies</h4>
      <p>
        These cookies are non-essential cookies, which are also specifically known as “measurement
        cookies” or “statistical cookies”. These cookies allow us to count visits and traffic
        sources so that we can measure and improve the performance of our website. They help us to
        know which pages are the most and least popular and how visitors move around the site. All
        information these cookies collect is aggregated and therefore anonymous.
      </p>
      <h4>Functional cookies</h4>
      <p>
        These cookies enable the website to offer enhanced functionality and personalization. They
        may be set by us or by third party providers whose services we have added to our pages. The
        following functionalities may or may not be activated when you accept this category:
      </p>
      <ul>
        <li>Live chat services</li>
        <li>Watch online videos</li>
        <li>Social media sharing buttons</li>
      </ul>
      <h4>Advertising / tracking cookies</h4>
      <p>
        These cookies are set by external advertising partners and are used for profiling and
        tracking data across multiple websites. If you accept these cookies, we may show our
        advertisements on other websites based on your user profile and preferences. These cookies
        may also save data about how many visitors have seen or clicked on our advertisements in
        order to optimize advertising campaigns.
      </p>
      <p>Information on the individual cookies can be found below.</p>
      <table>
        <thead>
          <th>Name</th> <th>Purpose</th> <th>Duration</th> <th>Provider</th> <th>Type</th>
        </thead>
        <tbody>
          <tr>
            <td style={{ fontWeight: 600 }}>Essential Cookies</td>
          </tr>
          <tr>
            <td>EXPRESS_LANG</td> <td>To store language preferences</td>
            <td>Session</td>
            <td>iMile</td> <td>First party</td>
          </tr>
          <tr>
            <td>EXPRESS_ACCESS_TOKEN</td>
            <td>Request header bearer user identity authentication</td>
            <td>Session</td>
            <td>iMile</td> <td>First party</td>
          </tr>
          <tr>
            <td>USER_INFO</td>
            <td>To share user login information in different domain names</td>
            <td>Session</td> <td>iMile</td> <td>First party</td>
          </tr>
          <tr>
            <td style={{ fontWeight: 600 }}>Performance cookies</td>
          </tr>
          <tr>
            <td>_fbp</td>
            <td>To track and record user activity and behaviour on the website</td>
            <td>30 days</td> <td>Facebook pixel</td> <td>Third party</td>
          </tr>
          <tr>
            <td>_ga_JZB7SGNDNX</td>
            <td>To track and record user activity and behaviour on the website</td>
            <td>400 days</td> <td>Google tag manager</td> <td>Third party</td>
          </tr>
          <tr>
            <td>_ga_XM0HEHEK5N</td>
            <td>To track and record user activity and behaviour on the website</td>
            <td>400 days</td> <td>Google tag manager</td> <td>Third party</td>
          </tr>
          <tr>
            <td>_ga</td>
            <td>To track and record user activity and behaviour on the website</td>
            <td>60 hours</td> <td>Google tag manager</td> <td>Third party</td>
          </tr>
          <tr>
            <td>_ga_TKCPHWF8FX</td>
            <td>To track and record user activity and behaviour on the website</td>
            <td>400 days</td> <td>Google tag manager</td> <td>Third party</td>
          </tr>
          <tr>
            <td style={{ fontWeight: 600 }}>Functional Cookies</td>
          </tr>
          <tr>
            <td>NID</td>
            <td>To play YouTube videos embedded on our website</td>
            <td>175 days</td> <td>Youtube</td> <td>Third party</td>
          </tr>
          <tr>
            <td>IDE</td>
            <td>To play YouTube videos embedded on our website</td>
            <td>208 days</td> <td>Youtube</td> <td>Third party</td>
          </tr>
          <tr>
            <td>Id</td>
            <td>To play YouTube videos embedded on our website</td>
            <td>399 days</td> <td>Youtube</td> <td>Third party</td>
          </tr>
          <tr>
            <td>VISITOR_INFO1_LIVE</td>
            <td>To play YouTube videos embedded on our website</td>
            <td>172 days</td>
            <td>Youtube</td> <td>Third party</td>
          </tr>
          <tr>
            <td>YSC</td>
            <td>To play YouTube videos embedded on our website</td>
            <td>Session</td> <td>Youtube</td> <td>Third party</td>
          </tr>
          <tr>
            <td style={{ fontWeight: 600 }}>Advertising / tracking cookies</td>
          </tr>
          <tr>
            <td>X-AB</td>
            <td>
              Snapchat tracks users' page viewing behaviour on websites. This can be used to analyze
              and optimize ad campaigns
            </td>
            <td>16 hours</td> <td>Snap Inc.</td> <td>Third party</td>
          </tr>
          <tr>
            <td>sc_at</td>
            <td>
              Snapchat tracks users' page viewing behaviour on websites. This can be used to analyze
              and optimize ad campaigns
            </td>
            <td>395d8h18m</td> <td>Snap Inc.</td> <td>Third party</td>
          </tr>
          <tr>
            <td>_scid</td>
            <td>
              Snapchat tracks users' page viewing behaviour on websites. This can be used to analyze
              and optimize ad campaigns
            </td>
            <td>395d8h18m</td> <td>Snap Inc.</td> <td>Third party</td>
          </tr>
          <tr>
            <td>_sctr</td>
            <td>
              Snapchat tracks users' page viewing behaviour on websites. This can be used to analyze
              and optimize ad campaigns
            </td>
            <td>395d8h18m</td> <td>Snap Inc.</td> <td>Third party</td>
          </tr>
          <tr>
            <td>_scid_r</td>
            <td>
              Snapchat tracks users' page viewing behaviour on websites. This can be used to analyze
              and optimize ad campaigns
            </td>
            <td>395d8h18m</td> <td>Snap Inc.</td> <td>Third party</td>
          </tr>
        </tbody>
      </table>
      <h3>Managing cookies</h3>
      <p>
        If necessary, we will ask for your consent to the use of certain cookies described in this
        Policy when you access the website. If so, a banner will appear when you first visit the
        website informing you of the use of such cookies. If you reject the cookies, we will not set
        any cookies on your device other than those for which consent is not required or those that
        are strictly necessary (including a cookie to indicate that you do not accept the cookies to
        be set when you visit the website).
      </p>
      <p>
        You can choose to opt out of all but the necessary cookies. You can also deactivate cookies
        by changing the settings of your website browser so that cookies are rejected. How to do
        this depends on the browser you are using. Most browsers allow you to change your cookie
        settings, which can usually be found in the 'Options' or 'Preferences' menu of your browser.
        Use the 'Help' option in your browser for more information. If you block the cookies, it is
        possible that you will not be able to enjoy all the technical features our website has to
        offer, and it may negatively affect your user experience.
      </p>
      <p>
        The modification or withdrawal of consent for the placement of cookies can be made using the
        following link: <a onClick={showDetailDrawer}>Consent Settings</a> .
      </p>
      <h3>Contact</h3>
      <p>
        If you have any questions, concerns or complaints in relation to this Cookie Policy, please
        feel free to contact us. You can reach us under{' '}
        <a href='mailto:dpobrazil@imile.me'>DPO@imile.me</a>
      </p>
    </Box>
  )
  return (
    <>
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
      {content}
      {detailDrawerOpen && detailDrawerContent}
      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
}

export default PrivacyPolicyBrazil

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["PrivacyPolicyBrazil"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
        }
      }
    }
    commonLocale: locale(ns: { eq: "common" }, language: { eq: $language }) {
      data
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`
